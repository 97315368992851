h1, h2, h3, h4, h5, h6, ul {
    margin: 0;
    padding: 0;
}


*{
	box-sizing: border-box;
}

body{
	background: #0C0C0C;
	font-family: $fontO;
}

.container{
	width: 1364px;
	padding: 0 11px;
	max-width: 100%;
	margin: 0 auto;
}

.page{
	padding: 86px 0;
	position: relative;
	z-index: 2;
	@include max(sm){
		padding: 60px 0;
	}
	@include max(xs){
		padding: 40px 0;
	}
	&::before{
		content: "";
		display: block;
		width: 100%;
		padding-bottom: 27.8125%;
		background: url("../img/top-bg.png") center bottom no-repeat;
		background-size: 100% 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
	}
}

.page-title{
	font-family: $fontM;
	font-style: normal;
	font-weight: 600;
	font-size: 48px;
	line-height: 59px;
	text-align: center;
	text-transform: uppercase;
	color: #FFFFFF;
	@include max(sm){
		font-size: 30px;
		line-height: 33px;
	}
	@include max(xs){
		font-size: 22px;
		line-height: 27px;
	}
	span{
		color: #7683FA;
	}
}

.page-subtitle{
	font-family: $fontO;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 25px;
	text-align: center;
	color: #FFFFFF;
	margin-top: 12px;
	@include max(xs){
		margin-top: 6px;
		font-size: 13px;
		line-height: 18px;
	}
}


.btn{
	width: 200px;
	height: 45px;
	background: #5E5CE6;
	border-radius: 10px;
	font-family: $fontO;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 20px;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #FFFFFF;
	cursor: pointer;
	text-decoration: none;
	display: inline-flex;
	&:hover{
		background: adjust-color(#5E5CE6, $lightness: -10%);
	}
}

.home{
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	background-image: url("../img/home-bg.png");
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center center;
	padding-left: 11px;
	padding-right: 11px;
	@include max(sm){
		padding-left: 11px;
		padding-right: 11px;
	}
	@include max(xs){
		background-image: url("../img/home-bg-mobile-x2.png");
		background-position: center bottom;
		padding-left: 11px;
		padding-right: 11px;
	}
}


.search-form{
	margin: 24px auto 0;
	max-width: 680px;
	width: 100%;
	@include max(xs){
		margin-top: 23px;
	}
	&__text{
		font-family: $fontO;
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 25px;
		text-align: center;
		color: #FFFFFF;
		@include max(xs){
			font-size: 13px;
			line-height: 18px;
		}
	}
	&__search{
		margin-top: 17px;
		width: 100%;
		@include max(xs){
			margin-top: 10px;
		}
	}
	&__droparea{
		margin-top: 40px;
		width: 100%;
		@include max(xs){
			margin-top: 31px;
		}
	}
}


.search{
	position: relative;

	&__input{
		width: 100%;
		background: #363644;
		border-radius: 8px;
		padding: 0 41px 0 22px;
		font-family: $fontO;
		font-style: normal;
		font-weight: 400;
		font-size: 15px;
		line-height: 20px;
		display: flex;
		align-items: center;
		color: #FFFFFF;
		height: 50px;
		border: none;
		outline: none;
		@include max(xs){
			padding: 0 31px 0 10px;
			height: 40px;
		}
	}
	&__btn{
		width: 31px;
		height: 31px;
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		background: transparent;
		border: none;
		outline: none;
		padding: 0;
		@include max(xs){
			width: 23px;
			height: 23px;
			right: 8px;
		}
		svg{
			width: 100%;
			height: 100%;
			use{
				fill: #7683FA;
			}
		}
	}
}


.droparea{
	border-radius: 8px;
	height: 384px;
	background: rgba(12, 12, 12, 0.75);
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='white' stroke-width='2' stroke-dasharray='8%2c 10' stroke-dashoffset='6' stroke-linecap='square'/%3e%3c/svg%3e");
	overflow: hidden;
	user-select: none;
	position: relative;
	@include max(xs){
		height: 336px;
	}
	&__preview{
		width: calc(100% - 2px);
		height: calc(100% - 2px);
		object-fit: cover;
		position: absolute;
		top: 1px;
		left: 1px;
		z-index: 1;
		background: black;
		display: none;
		border: 1px transparent;
	}
	&__inner{
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background: radial-gradient(50% 50% at 50% 50%, rgba(61, 77, 219, 0.2) 0%, rgba(20, 44, 255, 0) 100%);
	}
	&__icon{
		width: 78px;
		height: 78px;
		background: #1D1D1D;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 11px;
		@include max(xs){
			margin-bottom: 14px;
		}
		svg{
			width: 30px;
			use{
				fill: #7683FA;
			}
		}
	}
	&__text{
		font-family: $fontO;
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 25px;
		text-align: center;
		color: #FFFFFF;
		&--d{
			@include max(md){
				display: none;
			}
		}
		&--m{
			@include min(md){
				display: none;
			}
		}
	}
	&__btn{
		margin-top: 38px;
		@include max(xs){
			margin-top: 18px;
		}
	}

	&.dragover{
		.droparea__icon{
			background: #7683FA;
			svg{
				use{
					fill: #FFFFFF;
				}
			}
		}
	}
}


.not-found {
	margin-top: 34px;
	font-family: $fontO;
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 34px;
	text-align: center;
	color: #FFFFFF;
}



.results{
	margin-top: 34px;
	display: flex;
	flex-direction: column;
	align-items: center;
	@include max(xs){
		margin-top: 16px;
	}
	&__title{
		font-family: $fontM;
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 29px;
		color: #FFFFFF;
		width: 100%;
		padding-left: 34px;
		@include max(md){
			padding-left: 0;
		}
		@include max(xs){
			font-size: 16px;
			line-height: 20px;
		}
	}
	&__list{
		margin-top: 31px;
		width: 100%;
		@include max(xs){
			margin-top: 17px;
		}
	}
	&__btn{
		margin-top: 26px;
	}
}


.list{
	border-top: 1px solid #323554;
	&__item{
		border-bottom: 1px solid #323554;
	}
}

.movie{
	display: flex;
	justify-content: space-between;
	padding: 43px 0;
	@include max(md){
		display: block;
	}
	@include max(xs){
		padding: 31px 0 24px;
	}
	&__left{
		flex-grow: 1;
		display: flex;
		align-items: flex-start;
		padding-right: 30px;
		max-width: 711px;
		position: relative;
		@include max(md){
			max-width: 100%;
			width: 100%;
			padding-right: 0;
		}
	}
	&__poster{
		width: 86px;
		height: 129px;
		overflow: hidden;
		flex-shrink: 0;
		margin-right: 15px;
		@include max(xs){
			width: 72px;
			height: 108px;
			margin-right: 17px;
			margin-bottom: 37px;
		}
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			display: block;
		}
	}
	&__info{
		flex-grow: 1;
		padding-right: 30px;
		@include max(xs){
			padding: 0;
		}
	}
	&__title{
		font-family: $fontM;
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		color: #FFFFFF;
		@include max(xs){
			font-size: 18px;
			line-height: 22px;
		}
	}
	&__subtitle{
		font-family: $fontI;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #7683FA;
		margin-top: 5px;
		@include max(xs){
			font-size: 12px;
			line-height: 15px;
			margin-top: 4px;
		}
	}
	&__text{
		font-family: $fontM;
		font-style: normal;
		font-weight: 500;
		font-size: 13px;
		line-height: 16px;
		color: #FFFFFF;
		margin-top: 9px;
		@include max(xs){
			font-size: 12px;
			line-height: 15px;
			margin-top: 6px;
		}
	}

	&__stars{
		width: 73px;
		height: 30px;
		padding-left: 9px;
		border: 1px solid #FFFFFF;
		border-radius: 8px;
		color: #FFFFFF;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		@include max(xs){
			width: 56px;
			height: 28px;
			padding-left:6px;
			position: absolute;
			left: 8px;
			top: 117px;
		}
		svg{
			width: 19px;
			height: 16px;
			margin-right: 4px;
			@include max(xs){
				width: 16px;
				height: 14px;
				margin-right: 3px;
			}
			use{
				fill: #FFFFFF;
			}
		}
		span{
			font-family: $fontM;
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 22px;
			@include max(xs){
				font-size: 15px;
				line-height: 18px;
			}
		}
		&.low{
			border-color: #FA3C3C;
			color: #FA3C3C;
			svg{
				use{
					fill: #FA3C3C;
				}
			}
		}
		&.medium{
			border-color: #E9A82C;
			color: #E9A82C;
			svg{
				use{
					fill: #E9A82C;
				}
			}
		}
		&.high{
			border-color: #18B196;
			color: #18B196;
			svg{
				use{
					fill: #18B196;
				}
			}
		}
	}

	&__right{
		width: 600px;
		flex-shrink: 0;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		@include max(lg){
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			width: 450px;
		}
		@include max(md){
			width: 100%;
			margin-top: 15px;
		}
		@include max(xs){
			margin-top: 10px;
		}
	}
	&__see{
		font-family: $fontM;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #FFFFFF;
		@include max(lg){
			margin-bottom: 20px;
		}
		@include max(xs){
			margin-bottom: 15px;
			font-size: 13px;
			line-height: 16px;
		}
	}
	&__kinozal{
		width: 450px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 24px;
		flex-shrink: 0;
		@include max(md){
			width: 100%;
			grid-template-columns: 1fr 1fr 1fr;
		}
		@include max(sm){
			grid-template-columns: 1fr 1fr;
		}
		@include max(xs){
			grid-column-gap: 10px;
			grid-row-gap: 20px;
		}
		&-item{
			position: relative;
			a{
				width: 100%;
				height: 52px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 6px;
				background: #363644;
				border-radius: 8px;
				text-decoration: none;
				@include max(xs){
					height: 33px;
				}
				img{
					max-width: 100%;
					max-height: 100%;
					display: block;
				}
				&:hover{
					background: adjust-color(#363644, $lightness: -10%);
				}
				span{
					position: absolute;
					left: 50%;
					top: -11px;
					transform: translateX(-50%);
					display: block;
					max-width: 100%;
					width: max-content;
					min-width: 144px;
					min-height: 18px;
					border-radius: 3px;
					background: #2a2a2a;
					font-family: $fontM;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 17px;
					text-align: center;
					color: #FFFFFF;
					padding: 0 5px;
					@include max(xs){
						min-width: 114px;
						min-height: 15px;
						top: -9px;
						font-size: 12px;
						line-height: 15px;
					}
					&.orange{
						background: #FF5500;
					}
					&.darkorange{
						background: #FF3F2A;
					}
					&.red{
						background: #EA003D;
					}
					&.purple{
						background: #3C1A70;
					}
					&.green{
						background: #009806;
					}
				}
			}
		}
	}
}




.lds-dual-ring {
	display: inline-block;
	width: 80px;
	height: 80px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	z-index: 2;
	display: none;
}
.lds-dual-ring:after {
	content: " ";
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid #fff;
	border-color: #fff transparent #fff transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}