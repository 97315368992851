$fontI : 'Inter', sans-serif;
$fontO : 'Open Sans', sans-serif;
$fontM : 'Montserrat', sans-serif;


$media-list: (
		'xxs': 375,
		'xs': 499,
		'sm': 767,
		'md': 899,
		'lg': 1099,
		'xl': 1300,
		'xxl': 1900
);