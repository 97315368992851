@font-face {
	font-family: 'Inter';
	src: url('../fonts/Inter-Medium.woff2') format('woff2'),
	url('../fonts/Inter-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-Medium.woff2') format('woff2'),
	url('../fonts/Montserrat-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-Bold.woff2') format('woff2'),
	url('../fonts/OpenSans-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-Regular.woff2') format('woff2'),
	url('../fonts/OpenSans-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-Bold.woff2') format('woff2'),
	url('../fonts/Montserrat-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

